import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import LogoBlack from '../../assets/images/logo/logo-black.png';
import './index.css';

export default class Footer extends React.Component {
    render() {
        const { width } = this.props.data;

        return (
            <footer className='shadow border-top py-3 mt-5'>
                <Container fluid>
                    <div className={`${width >= 780 ? `px-3` : ``}`}>
                        <Row>
                            <Col md={4}>
                                <Image src={LogoBlack} className='img-fluid' width='120px' alt='Africa|XYZ' />
                            </Col>

                            <Col md={8}>
                                <div className='menu mt-2 d-flex justify-content-end'>
                                    <ul>
                                        <li className='nav-item'>
                                            <a href='https://google.com' className='nav-link social'>
                                                <i className='fab fa-facebook-f'></i>
                                            </a>
                                        </li>

                                        <li className='nav-item'>
                                            <a href='https://google.com' className='nav-link social'>
                                                <i className='fab fa-instagram'></i>
                                            </a>
                                        </li>

                                        <li className='nav-item mr-2'>
                                            <a href='https://google.com' className='nav-link social'>
                                                <i className='fab fa-whatsapp'></i>
                                            </a>
                                        </li>

                                        <li className='nav-item'>
                                            <a href='https://google.com' className='nav-link'>
                                                <i className='fas fa-globe mr-1'></i> English
                                            </a>
                                        </li>

                                        <li className='nav-item'>
                                            <a href='https://google.com' className='nav-link'>
                                                $USD
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </footer>
        );
    }
}