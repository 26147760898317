import React from 'react';
import { Container, Form, Row, Col, InputGroup, Button, Card, Image } from 'react-bootstrap';
import { popular, category, story } from '../../assets/data/static';
import Family from '../../assets/images/banner/family.jpg';
import AppLayout from '../layouts/AppLayout';

export default class HomeView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: false,
            scrool: false,
            width: 0
        }
    }

    render() {
        return (
            <AppLayout>
            {
               ({ width }) => (
                    <>
                    <div className='hero d-flex align-items-center'>
                        <Container fluid>
                            <Row className={`${width >= 780 ? `px-3` : ``}`}>
                                <Col lg={6} md={12}>
                                    <h2 className='title'>
                                        {
                                            width >= 780 ? 
                                                <>Building The 1st <br /> Pan-African Multinational</>
                                            : <>Building The 1st Pan-African Multinational</>
                                        }
                                    </h2>

                                    <Form className='my-4 pt-2'>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>
                                                    <i className='fas fa-search'></i>
                                                </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control placeholder='Try "building mobile app"' />

                                            <InputGroup.Append>
                                                <Button variant="success" className='px-4'>Search</Button>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Form>

                                    <div className='label'>
                                        <span className='mr-2'>Our Pillars: </span>
                                        <Button variant='primary' className='mb-2'>Made In Africa</Button>
                                        <Button variant='primary' className='mb-2'>Digitalization</Button>
                                        <Button variant='primary' className='mb-2'>Payment</Button>
                                        <Button variant='primary' className='mb-2'>Employment</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className='popular py-5 bg-white'>
                        <Container fluid>
                            <div className={`${width >= 780 ? `px-3` : ``}`}>
                                <Row>
                                    <Col md={12}>
                                        <h3>
                                            Our Offices <span className='see-more float-right mt-3'>See More <i className='fas fa-arrow-right'></i></span>
                                        </h3>
                                    </Col>
                                </Row>

                                <Row>
                                    {
                                        popular.map((data, key) => (
                                            <Col md={3} className='mt-4' key={key}>
                                                <Card className="bg-dark text-white">
                                                    <Card.Img src={data.image} alt="Card image" />
                                                    
                                                    <Card.ImgOverlay>
                                                        <Card.Text>
                                                            <p>
                                                                {data.head}
                                                            </p>

                                                            <h3>{data.title}</h3>
                                                        </Card.Text>
                                                    </Card.ImgOverlay>
                                                </Card>
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </div>
                        </Container>
                    </div>

                    <div className='our-story py-5'>
                        <Container fluid>
                            <div className={`${width >= 780 ? `px-3` : ``}`}>
                                <Row>
                                    <Col md={6}>
                                        <p>SOLVING AFRICAN PROBLEMS</p>
                                        <h2>Our Mission & Goals</h2>

                                        <div className={`content mt-4 ${width >= 780 ? `pr-5` : ``}`}>
                                            {
                                                story.map((data, key) => (
                                                    <div className='content-single mb-4' key={key}>
                                                        <p className='top'>
                                                        <i className='fas fa-check mr-1'></i> {data.title}
                                                        </p>

                                                        <p className='mt-2 bottom'>
                                                            {data.description}
                                                        </p>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </Col>

                                    <Col md={6}>
                                        <Image src={Family} className='img-fluid w-100 mt-5' alt='our story' />
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>

                    <div className='category py-5'>
                        <Container fluid>
                            <div className={`${width >= 780 ? `px-3` : ``}`}>
                                <Row>
                                    <Col md={12}>
                                        <h3>Our Presence In Africa</h3>
                                    </Col>
                                </Row>

                                <Row className='mt-4'>
                                    {
                                        category.map((data, key) => (
                                            <Col md={3} xs={6} className='mb-4' key={key}>
                                                <div className='text-center'>
                                                    <Image src={data.image} width='60px' alt='Category' />
                                                    <p className='mt-2'><b>{data.title}</b></p>
                                                </div>
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </div>
                        </Container>
                    </div>

                    <div className='banner'>
                        <Container fluid>
                            <div className={`${width >= 780 ? `px-3` : ``}`}>
                                <div className='banner-img p-5 text-white'>
                                    <Row>
                                        <Col md={4}>
                                            <h3><b>Fiverr</b> Logo maker.</h3>

                                            <h3 className='mt-4 mb-3'>Make an incredible logo <br /> in minutes</h3>
                                            <p>Pre-designed by top talent. Just add your touch.</p>

                                            <Button variant='primary' className='mt-4'>Try Fiverr Logo Maker</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Container>
                    </div>
                    </>
                 )
            }
            </AppLayout>
        );
    }
}