import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Container, Nav, Form, Button, Image } from 'react-bootstrap';
import LogoBlack from '../../assets/images/logo/logo-black.png';
import LogoWhite from '../../assets/images/logo/logo-white.png';

export default class NavBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: false
        }
    }
    
    handleNavExpand = () => {
        const { menu } = this.state;
        this.setState({ menu: !menu });
    }

    render() {
        const { scrool } = this.props.data;
        const { menu } = this.state;

        return (
            <Navbar 
                className={`header ${scrool ? `scrool` : ``}`}
                onToggle={this.handleNavExpand}
                expanded={menu}
                collapseOnSelect
                sticky='top'
                expand='lg'
            >
                <Container fluid>
                    <Navbar.Brand className='logo font-pencil'>
                        <Link to='/'>
                            <Image src={!scrool ? LogoWhite : LogoBlack} className='img-fluid' width='150px' />
                        </Link>
                    </Navbar.Brand>
                    
                    <Navbar.Toggle className={`${!scrool ? `text-white` : `text-dark`}`} aria-controls='navBarMenu'>
                        {!menu ? (
                            <i className='fas fa-bars'></i>
                        ) : (
                            <i className='fas fa-times'></i>
                        )}
                    </Navbar.Toggle>
                    
                    <Navbar.Collapse>
                        <Nav className="ml-auto">
                            <li className='nav-item'>
                                <Link to='/business' className='nav-link'>Find a Business</Link>
                            </li>

                            <li className='nav-item'>
                                <Link to='/' className='nav-link'>Explore</Link>
                            </li>

                            <li className='nav-item'>
                                <Link to='/' className='nav-link'>$USD</Link>
                            </li>

                            <li className='nav-item'>
                                <Link to='/' className='nav-link'>Become a Seller</Link>
                            </li>

                            <li className='nav-item mr-3'>
                                <Link to='/' className='nav-link'>Sign In</Link>
                            </li>
                        </Nav>

                        <Form inline>
                            <Button variant="primary" className='join'>Join</Button>
                        </Form>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }
}