import React from 'react';
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";

export default class AppLayout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scrool: props.scrool ?? false,
            width: 0
        }
    }

    componentDidMount() {
        this.setState({ width: window.innerWidth });
        const { scrool } = this.props;

        !scrool && window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll = () => {
        if (window.scrollY > 50) {
            this.setState({ scrool: true });
        } else {
            this.setState({ scrool: false });
        }

        this.setState({ width: window.innerWidth });
    }

    render() {
        const { children } = this.props;
        const data = this.state;

        return (
            <>
                <NavBar data={data} />
                
                <main>
                    {children(data)}
                </main>

                <Footer data={data} />
            </>
        );
    }
}