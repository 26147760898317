import KigaliOffice from '../../assets/images/banner/kigali-office.jpg';
import Blantyre from '../../assets/images/banner/blantyre.jpg';
import TanzaniaBanner from '../../assets/images/banner/tanzania.jpg';
import SenegalBanner from '../../assets/images/banner/senegal.jpg';

import Rwanda from '../../assets/images/flags/rwanda.png';
import Malawi from '../../assets/images/flags/malawi.png';
import Tanzania from '../../assets/images/flags/tanzania.png';
import Senegal from '../../assets/images/flags/senegal.png';
import Gabon from '../../assets/images/flags/gabon.png';
import Madagascar from '../../assets/images/flags/madagascar.png';
import IvoryCoast from '../../assets/images/flags/ivory-coast.png';
import Benin from '../../assets/images/flags/benin.png';

export const popular = [
    {
        head: 'Rwanda',
        title: 'Kigali',
        image: KigaliOffice
    },
    {
        head: 'Malawi',
        title: 'Blantyre',
        image: Blantyre
    },
    {
        head: 'Tanzania',
        title: 'Dar es Salaam',
        image: TanzaniaBanner
    },
    {
        head: 'Senegal',
        title: 'Dakar',
        image: SenegalBanner
    },
];

export const story = [
    {
        title: 'Promoting Made in Africa',
        description: 'We help promote made in Africa products through our distribution network across Rwanda, Malawi & Tanzania.'
    },
    {
        title: 'Digitalization of Africa',
        description: 'We want to become the number 1 partner of entrepreneurs & companies of Africa in the process of Digitalization of the continent.'
    },
    {
        title: 'Facilitating Payments',
        description: "Help entrepreneurs to sell their products & services worldwide and receive their payments everywhere in Africa"
    },
];

export const category = [
    {
        image: Rwanda,
        title: 'Rwanda'
    },
    {
        image: Malawi,
        title: 'Malawi'
    },
    {
        image: Tanzania,
        title: 'Tanzania'
    },
    {
        image: Senegal,
        title: 'Senegal'
    },
    {
        image: Gabon,
        title: 'Gabon'
    },
    {
        image: Madagascar,
        title: 'Madagascar'
    },
    {
        image: IvoryCoast,
        title: 'Ivory coast'
    },
    {
        image: Benin,
        title: 'Benin'
    },
];

export const faq = [
    {
        title: "Will the freelancer be committed to my business' NDA?",
        content: "Here on Fiverr it's just like working with any other freelancer. You can ask them to sign your NDA or any other legal document your legal department deems necessary. You can also find additional confidentiality obligations of freelancers mandated under Fiverr's Terms of Service."
    },
    {
        title: "Is Fiverr Business really offering a year for free?",
        content: "Yes, it's absolutely free! And what's even better? It comes without any conditions committing to use it for the second year. Please note that individual freelancer services ordered through Fiverr must still be paid at their respective prices – just like on Fiverr."
    },
    {
        title: "How is Fiverr Business different from the Fiverr Marketplace?",
        content: "Fiverr Business is all about collaboration. Our business tool allows you to organize and manage all your projects, communication, deliveries, and budgeting in one dashboard. You can share a payment method with team members, save and share favorite sellers for quick access, and contact a dedicated Fiverr Business Success Manager to help you find the right sellers."
    },
    {
        title: "How do I know that the freelancer is qualified to complete my project?",
        content: "That's exactly what Fiverr Business has set out to guarantee by designing a unique catalog of exclusively business-relevant, top-tier freelancers. You can browse work samples and reviews first, and if you're still unsure – reach out to a designated Fiverr Business Success Manager to help find you the right match."
    }
]