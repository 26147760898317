import React from "react";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Link } from "react-router-dom";
import HomeView from './resources/views/HomeView';
import BusinessView from "./resources/views/BusinessView";

export default class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path='/' component={HomeView}  />
          <Route path='/business' component={BusinessView}  />

          <Route path='*'>
            <div className='container py-5'>
              <div className='row justify-content-center'>
                <div className='col-md-6'>
                  <h2>404 Not Found</h2>
                  <Link to='/' className='btn btn-primary'>Go Back</Link>
                </div>
              </div>
            </div>
          </Route>
        </Switch>
      </Router>
    );
  }
}