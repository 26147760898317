import React from 'react';
import { Col, Container, Row, Image, Button } from 'react-bootstrap';
import AppLayout from "../layouts/AppLayout";
import Catalog from '../../assets/images/banner/catalog.png';
import Accordion from '../components/Accordion';
import { faq } from '../../assets/data/static';

export default class BusinessView extends React.Component {
    render() {
        return (
            <AppLayout>
                {
                    ({ width }) => (
                       <>
                       <div className='hero busiz bg-dark-blue d-flex align-items-center'>
                        <Container fluid>
                                <Row className={`${width >= 780 ? `px-3` : ``}`}>
                                    <Col lg={6} className={`${width >= 780 ? `pr-5` : ``}`}>
                                        <h1 className='title'>
                                            Boost your team <br /> with <span className='font-pencil'>exceptional</span> <br /> talent
                                        </h1>

                                        <p className='content'>
                                            Set your team up for success every time with the help of freelance talent you can count on.
                                        </p>

                                        <div className='arrow cursor'></div>
                                    </Col>

                                    <Col lg={6}>
                                        <video
                                            controls={false}
                                            preload='metadata'
                                            playsInline={false}
                                            autoPlay={false}
                                            width='100%'
                                        >
                                            <source src='https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd_nl/cejwcs5uqtbnyg5wboo5' />
                                            Your browser does not support the video tag.
                                        </video>
                                    </Col>
                                </Row>
                            </Container>
                       </div>

                       <div className='catalog py-5'>
                           <Container>
                               <Row className='justify-content-center'>
                                   <Col md={4} className='text-center'>
                                       <h4>
                                           Access a catalog of verified, <br />
                                           trusted talent
                                        </h4>

                                        <p className='text-muted mt-4'>
                                            Expand your team as needed with experienced freelancers already vetted for business projects.
                                        </p>
                                   </Col>
                               </Row>
                           </Container>

                           <Container fluid className='mt-5 p-0'>
                               <Row>
                                   <Col md={12}>
                                       <Image src={Catalog} alt='Catalog' className='img-fluid w-100' />
                                   </Col>
                               </Row>
                           </Container>
                       </div>

                       <div className='bg-grey py-5'>
                           <Container>
                               <Row>
                                   <Col md={5}>
                                       <div className='py-5 my-5'>
                                        <h3>Finding the right talent? Leave that to us</h3>
                                        <p className='mt-4 mb-5'>A team of Fiverr Business Success Managers is here to help match you with the best talent for your team - no need for endless guessing and interviews.</p>

                                        <Button variant='success' className='px-5 py-2'>Visit Now</Button>
                                       </div>
                                   </Col>

                                   <Col md={7}>
                                       <Image src='https://fiverr-res.cloudinary.com/q_auto,f_auto,w_660,dpr_2.0/v1/attachments/generic_asset/asset/bc05537ddbcae4f68ad0b17659b45dd0-1606732443796/right-talent-desktop-660-x2.png' className='img-fluid w-100' alt='Product' />
                                   </Col>
                               </Row>

                               <Row className='mt-5'>
                                    <Col md={7}>
                                       <Image src='https://fiverr-res.cloudinary.com/q_auto,f_auto,w_660,dpr_2.0/v1/attachments/generic_asset/asset/5364183d1377de2e002df16f78f56ef3-1599950021826/scattered-feedback-desktop-660-x2.png' className='img-fluid w-100' alt='Product' />
                                    </Col>

                                    <Col md={5}>
                                       <div className='py-5 my-5'>
                                        <h3>Turn scattered feedback into productive teamwork</h3>
                                        <p className='mt-4 mb-5'>Collaborate with your team, manage projects, and share freelancers - all in one workspace.</p>

                                        <Button variant='success' className='px-5 py-2'>Visit Now</Button>
                                       </div>
                                    </Col>
                               </Row>

                               <Row className='mt-5'>
                                   <Col md={5}>
                                       <div className='py-5 my-5'>
                                        <h3>Simplify budgeting and manage with transparency</h3>
                                        <p className='mt-4 mb-5'>Skip all the overhead of multiple freelancers, monitor your team's progress, approve transactions, and set budgets with ease.</p>

                                        <Button variant='success' className='px-5 py-2'>Visit Now</Button>
                                       </div>
                                   </Col>

                                   <Col md={7}>
                                       <Image src='https://fiverr-res.cloudinary.com/q_auto,f_auto,w_660,dpr_2.0/v1/attachments/generic_asset/asset/5364183d1377de2e002df16f78f56ef3-1599950021839/simplify-budgeting-desktop-660-x2.png' className='img-fluid w-100' alt='Product' />
                                   </Col>
                               </Row>
                           </Container>
                       </div>

                       <div className='faq py-5'>
                            <Container>
                                <Row className='justify-content-center'>
                                    <Col md={8}>
                                        <h3>Common Questions</h3>
                                    </Col>

                                    <Col md={8} className='mt-2'>
                                        {
                                            faq.map(value => (
                                                <Accordion {...value} />
                                            ))
                                        }
                                    </Col>
                                </Row>
                            </Container>
                       </div>
                       </>
                    )
                }
            </AppLayout>
        )
    }
}