import React from 'react';
import { Accordion as AccordionBootStrap, Card, Button } from 'react-bootstrap';

export default class Accordion extends React.Component {
    render() {
        const { title, content } = this.props;

        return (
            <AccordionBootStrap>
                <Card>
                    <Card.Header>
                        <AccordionBootStrap.Toggle as={Button} variant="link" eventKey="0">
                            <div className='d-flex justify-content-between'>
                                {title}
                            </div>
                        </AccordionBootStrap.Toggle>
                    </Card.Header>
                    <AccordionBootStrap.Collapse eventKey="0">
                        <Card.Body className='text-justify'>{content}</Card.Body>
                    </AccordionBootStrap.Collapse>
                </Card>
            </AccordionBootStrap>
        );
    }
}